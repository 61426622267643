
import { mapState, mapMutations, mapActions } from 'vuex'

import LocationCard from './LocationCard.vue'

export default {
  name: 'LocationResults',
  components: {
    LocationCard
  },
  data() {
    return {
      availableHandoffModes: ['pickup', 'curbside', 'drivethru', 'reservation']
    }
  },
  props: {
    config: Object,
  },
  mounted() {
    if (this.appRadar['onload_nearby_locations']) {
      this.geoLocate();
    }
  },
  computed: {
    ...mapState([ 'user', 'appInfo' ]),
    ...mapState({
      handoffMode: state => state.order.handoffMode,
      searched: state => state.locations.searched,
      results: state => state.locations.results,
      resultsTab: state => state.locations.resultsTab,
      favLocs: state => state.members.favLocs,
    }),
    showResults() {
      return this.availableHandoffModes.includes(this.handoffMode);
    },
    appRadar() {
      return this.appInfo['radar_map']
    },
  },
  methods: {
    ...mapActions(['sendRequest']),
    ...mapMutations(['setLoading', 'setErrors', 'setGeolocation']),
    ...mapMutations({
      setResults: 'locations/setResults',
      setResultsTab: 'locations/setResultsTab',
    }),
    geoLocate() {
      if (navigator.geolocation) {
        this.setLoading(true)
        navigator.geolocation.getCurrentPosition(this.coordSearch, this.coordSearchError)
      } else {
        this.setErrors(['Please enable location services and try again.'])
      }
    },
    async coordSearch(position) {
      let coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      await this.setGeolocation(coords);
      let results = await this.sendRequest({
        method: 'get',
        url: 'locations',
        params: coords
      })
      
      // Only set results if there are any
      if(results?.data?.locations?.length > 0) {
        this.setResults(results.data.locations)
      }
      
      this.setLoading(false)
    },
    coordSearchError() {
      this.setLoading(false)
      // this.setErrors(['We couldn’t locate you. Please enter your address.'])
    },
  }
}
