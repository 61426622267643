
import { mapState, mapMutations } from 'vuex'
import isMobile from "@/mixins/is-mobile";
import LocationsSearchView from '@/components/locations/search/views/Default.vue'
export default {
  name: 'LocationsPage',
  mixins: [ isMobile ],
  components: {
    LocationsSearchView,
  },  
  head() {
    return this.$utils.head(this.story)
  },
  async asyncData({ $utils }) {
    const locations = await $utils.content('locations')
    if (!locations) {
      return {
        story: null,
        locationSearch: undefined,
      }
    }

    const sections = locations.content.sections || []
    const sectionFound = sections.find(section => section.component === 'location-search')
    const locationSearch = sectionFound ? sectionFound : null

    return {
      story: locations,
      locationSearch
    }
  },
  computed: {
    ...mapState([ 'location' ]),
    ...mapState({
      handoffMode: state => state.order.handoffMode,
      vehicleInfo: state => state.locations.vehicleInfo,
      deliveryAddress: state => state.locations.deliveryAddress,
    }),
    config() {
      return {
        headerInfo: {
          title: this.title,
          description: this.description,
          leftAction: this.leftAction,
          rightAction: this.rightAction,
        },
        handoffInfo: {
          title: 'Select a Location',
        },
        timeInfo: {
          title: 'Order Time',
        },
        // TODO add class styles options
        locationCardInfo: {
          separator: '•',
          showServicesOnCard: true,
          showServicesOnOperation: false,
          locationPageAction: 'View Details',
          locationPageIcon: '',
        },
      }
    },
    title() {
      if (this.location || this.deliveryAddress) {
        if (this.handoffMode === 'pickup') return 'Pickup From'
        if (this.handoffMode === 'curbside') return this.isMobile ? 'Curbside From' : 'Curbside Pickup From'
        if (this.handoffMode === 'drivethru') return 'Drive Thru At'
        if (this.handoffMode === 'delivery') return 'Delivery To'
      }
      if (this.locationSearch) {
        return this.locationSearch.title
      }
      return ''
    },
    description() {
      if (this.location || this.deliveryAddress) {
        return ''
      }
      if (this.locationSearch) {
        return this.locationSearch.copy
      }
      return ''
    },
    leftAction() {
      return {
        show: false,
        // iconName: this.location || this.deliveryAddress ? 'arrow-left-icon' : 'location-icon',
        // iconClasses: this.location || this.deliveryAddress ? 'circle' : 'no-circle',
        // callback: () => {
        //   if (this.location || this.deliveryAddress) {
        //     return this.handleClose()
        //   }
        //   return
        // },
      }
    },
    rightAction() {
      return {
        // TODO: removed See All Locations for now
        show: this.location || this.deliveryAddress ? true : false,
        text: this.location || this.deliveryAddress ? 'Change Location' : 'See All Locations',
        iconName: this.location || this.deliveryAddress ? 'location-icon' : 'arrow-right-icon',
        iconClasses: this.location || this.deliveryAddress ? 'location' : 'arrow',
        callback: () => {
          if (this.location || this.deliveryAddress) {
            return this.handleClose()
          }
          return this.$router.push('/locations/all')
        },
      }
    },
  },
  methods: {
    ...mapMutations([ 'setLocation', 'setMenu' ]),
    ...mapMutations({
      setDeliveryAddress: 'locations/setDeliveryAddress',
      setVehicleInfo: 'locations/setVehicleInfo',
    }),
    handleClose() {
      if (this.location) {
        this.setLocation(null)
        this.setMenu(null)
      }
      if (this.deliveryAddress) {
        this.setDeliveryAddress(null)
      }
      if (this.vehicleInfo) {
        this.setVehicleInfo(null)
      }
    },
  }
}
