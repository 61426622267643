
import { mapState, mapMutations, mapActions } from 'vuex'

import HandoffModeSelector from '@pleinair/bigcolony-vue/components/common/HandoffModeSelector/index.vue'
import Icon from '@pleinair/bigcolony-vue/components/common/Icon/index.vue'

import LocationPhoneInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/PhoneInfo.vue'
import LocationAddressInfo from '@pleinair/bigcolony-vue/components/locations/search/elements/AddressInfo.vue'
import LocationOperationInfo from './LocationOperationInfo.vue'

export default {
  name: 'LocationCard',
  components: {
    Icon,
    HandoffModeSelector,
    LocationPhoneInfo,
    LocationAddressInfo,
    LocationOperationInfo,
  },
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    showHandoffModeSelector: {
      type: Boolean,
      default: false,
    },
    showChip: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {
        return {
          separator: '|',
          showServicesOnCard: false,
          showServicesOnOperation: true,
          locationPageAction: 'View Restaurant Details',
          locationPageIcon: '',
        }
      }
    },
  },
  data() {
    return {
      brands: [],
    }
  },
  mounted() {
    this.separateBrands()
  },
  computed: {
    ...mapState(['user', 'appInfo', 'cart']),
    ...mapState({
      favLocs: state => state.members.favLocs,
      handoffMode: state => state.order.handoffMode,
      loading: state => state.loading,
    }),
    
    appHandoffModes() {
      return this.appInfo.order['handoff_modes']
    },
    locationHandoffModes() {
      if (this.data) {
        return this.$utils.locationHandoffModes(this.data, this.appHandoffModes)
      }
      return []
    },
    locationDisabled() {
      return !this.data.services[this.handoffMode]
    },
    services() {
      if (this.config && this.config.showServicesOnCard && this.data && this.data.services) {
        const services = []
        if (this.data.services.delivery) {
          services.push({
            name: 'Delivery',
            iconName: 'delivery-icon',
            iconClasses: 'delivery-icon',
          })
        }
        if (this.data.services.pickup) {
          services.push({
            name: 'Pickup',
            iconName: 'pickup-icon',
            iconClasses: 'pickup-icon',
          })
        }
        if (this.data.services.curbside) {
          services.push({
            name: 'Curbside',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        if (this.data.services.drivethru) {
          services.push({
            name: 'Drive Thru',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        return services
      }

      return []
    },
    multibrandCategories() {
      let categories = [];
      this.config?.multibrand?.brands.map((m) => {
        Object.keys(this.data).map((k) => {
          if (k === m.key && this.data[k] === true) {
            categories.push(m);
          }
        });
      });
      return categories;
    },
  },
  methods: {
    ...mapMutations(['setLocation', 'setLoading', 'setMenu']),
    ...mapMutations({
      setOrderMode: 'order/setOrderMode',
      setSelectedDay: 'order/setSelectedDay',
      setSelectedTime: 'order/setSelectedTime',
    }),
    ...mapActions(['sendRequest', 'trackEvent', 'changeLocation', 'abandonCart', 'transferCart', 'createCart']),
    ...mapActions({
      saveFavLoc: 'members/saveFavLoc',
      deleteFavLoc: 'members/deleteFavLoc',
    }),
    async favorite() {
      const favLocFound = this.favLocs.find(l => l.id === this.data.id)
      if (favLocFound) {
        await this.deleteFavLoc(this.data.menu_id)
      } else {
        const response = await this.saveFavLoc(this.data.menu_id)
        if (response) {
          this.trackEvent({
            name: 'location_favorited',
            data: this.data,
            windowObject: window
          })
        }
      }
    },
    handleSelectLocation() {
      const location = this.data;
      // Transfer cart when changing locations
      if (location.services?.transfercart && this.cart?.menu_id !== location.menu_id) {
        this.transferCart(location.menu_id)
      } else {
        this.abandonCart()
      }

      if (location) {
        this.trackEvent({
          name: 'select_location',
          data: location,
          windowObject: window
        })
      }

      this.setLocation(location)
      this.setMenu(null)
    },

    handleLaterButtonClick() {
      this.handleSelectLocation();
      setTimeout(() => {
        this.setOrderAhead();
      }, 1000);
    },

    separateBrands() {
      this.brands = [];

      if (this.multibrandCategories.length > 0) {
        let brands = [];
        this.multibrandCategories.map((c) => {
          if (c.brand !== this.appInfo?.name) {
            let brandImage = c?.logo;

            // Push brand
            const brand = {
              name: c.brand,
              category: c.brand,
              image: brandImage ? brandImage : "",
            };
            brands.push(brand);
          }
        });
        this.brands = brands;
      }
    },

    async setOrderAhead() {
      if (!this.cart) {
        await this.createCart()
      }
      this.setOrderMode('advance')
    }
  },
}
